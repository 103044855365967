.background {
  position: fixed;
  z-index: 50;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 40, 0.4);
}
.container {
  z-index: 50;
  width: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--white);
  overflow: auto;
}
.openedContainer {
  height: 100%;
  max-width: 620px;
  width: 620px;
  padding: 60px 70px;
}
.logo {
  max-width: 178px;
  width: 100%;
}
.main {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}
.headContent {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
.backBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 54px;
  height: 54px;
  background-color: var(--back-gray);
  border-radius: 6px;
  border: 1px solid transparent;
}
.headContent span {
  font-size: 24px;
  font-family: "bold";
  text-transform: uppercase;
}
.subTitle {
  font-size: 18px;
}
.input {
  width: 100%;
  padding: 12px 24px;
  font-size: 14px;
  background-color: var(--back-gray);
  border-radius: 5px;
  border: 1px solid transparent;
}
.inputError {
  border-color: var(--red);
}
.passwordContainer {
  position: relative;
  display: flex;
  align-items: center;
}
.eye {
  position: absolute;
  right: 24px;
  cursor: pointer;
}
.forgotPassword {
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--red);
  border-radius: 5px;
  padding: 20px;
  border: none;
  cursor: pointer;
  position: relative;
}
.darkBtn {
  background-color: var(--black);
}
.btn span {
  color: var(--white);
  font-size: 16px;
}
.line {
  width: 100%;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
}
.twoInps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.btnArrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 25px;
  right: 38px;
}

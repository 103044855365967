.container {
  position: relative;
}
.btn {
  background-color: var(--dark-gray);
  display: flex;
  align-items: center;
  column-gap: 10px;
  background: #eaeff5;
  border-radius: 5px;
  padding: 5px 17px;
  cursor: pointer;
  border: 1px solid transparent;
  height: 30px;
  box-sizing: border-box;
}
.btn:hover {
  border-color: #9bbbe3;
}
.shortBtn {
  background-color: transparent;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  height: 30px;
  box-sizing: border-box;
}
.shortBtn span {
  display: none;
}
.langFlag {
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.btnCurrencyText {
  font-size: 14px;
  text-transform: uppercase;
}
.openedContainer {
  position: absolute;
  background-color: #fff;
  top: 30px;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-family: bold;
  width: 180px;
  z-index: 9;
}
@media only screen and (max-width: 1440px) {
  .btn {
    padding: 7px 12px;
  }
}

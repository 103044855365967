.container {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 30px;
  margin-bottom: 46px;
}
.homeBtn:hover svg path {
  fill: var(--red);
}
.line {
  width: 22px;
  height: 1px;
  background-color: var(--black);
  opacity: 0.4;
}
.title {
  color: var(--black);
  opacity: 0.8;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

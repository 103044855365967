.product {
  position: relative;
}
.productImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  min-height: 200px;
  min-width: 100px;
}
.plusBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: var(--red);
  cursor: pointer;
  border-radius: 90px;
}
.infoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  top: 60px;
  right: 10px;
  width: auto;
  height: 0;
  padding: 0;
  border: 0px solid var(--white);
  column-gap: 12px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(31px);
  border-radius: 5px;
  overflow: hidden;
}
.showedInfoContainer {
  border: 1px solid var(--white);
  height: auto;
  min-width: 290px;
  padding: 15px;
}
.smallImg {
  max-width: 96px;
  width: 100%;
  height: 96px;
}
.smallImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.aboutProduct {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.aboutProductInfo {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  cursor: pointer;
}
.price {
  font-size: 16px;
  font-family: "bold";
}
.category {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: "bold";
  width: 100%;
  font-size: 10px;
  margin-bottom: 5px;
  word-break: break-word;
}
.name {
  height: 17px;
  width: 100%;
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
}
.priceMonth {
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
}
.btnsContainer {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.btnsContainer svg {
  cursor: pointer;
}

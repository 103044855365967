.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 60px;
  column-gap: 30px;
}
.video,
.video video {
  max-width: 705px;
  width: 100%;
}
.rightBlock {
  max-width: 705px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.timeBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  column-gap: 20px;
  padding: 25px 48px 18px 40px;
}
.imgTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
}
.percent {
  max-width: 60px;
}
.titles {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}
.startTime {
  color: rgba(152, 152, 152);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
.timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 6px;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3px;
}
.colon {
  margin-bottom: 17px;
  font-size: 19px;
}
.countdown {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  justify-content: center;
  background-color: var(--back-gray);
}
.nameof_count {
  font-size: 12px;
  color: rgb(208, 208, 208);
}
.swiperContainer {
  max-width: 705px;
  width: 100%;
  position: relative;
}
.loadContentVideo {
  max-width: 705px;
  width: 100%;
  aspect-ratio: 10 / 7.8;
}
.loadContentTimer {
  width: 100%;
  height: 108px;
}
.loadContentProduct {
  max-width: 336px;
  width: 100%;
  aspect-ratio: 10 / 5.2;
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 15px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 15px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}

@media only screen and (max-width: 1440px) {
  .imgTitle {
    column-gap: 24px;
  }
  .titles .title {
    font-size: 22px;
  }
  .titles .startTime {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1280px) {
  .main {
    flex-direction: column;
    row-gap: 20px;
  }
  .rightBlock,
  .swiperContainer {
    max-width: none;
  }
}
@media only screen and (max-width: 600px) {
  .timeBlock {
    row-gap: 16px;
    padding: 25px 26px 18px 26px;
    flex-direction: column;
  }
  .imgTitle {
    column-gap: 20px;
  }
  .titles .title {
    font-size: 18px;
  }
  .titles .startTime {
    font-size: 14px;
  }
}

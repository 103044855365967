@font-face {
  font-family: "bold";
  src: local("Mardoto-Bold"),
    url("./assets/fonts/Mardoto-Bold.ttf") format("truetype"),
    local("arial") format("truetype");
  font-display: optional;
}
@font-face {
  font-family: "medium";
  src: local("Mardoto-Medium"),
    url("./assets/fonts/Mardoto-Medium.ttf") format("truetype");
  font-display: optional;
}
@font-face {
  font-family: "regular";
  src: local("Mardoto-Regular"),
    url("./assets/fonts/Mardoto-Regular.ttf") format("truetype");
  font-display: optional;
}

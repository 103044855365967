.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 50;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  padding: 30px;
  row-gap: 30px;
}
.openMenu {
  transform: translateY(0%);
}
.headContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.closeBtn {
  cursor: pointer;
}
.socIconsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 28px;
  align-items: center;
}
.socIcon path {
  fill: var(--black);
}
.socIcon:hover path {
  fill: var(--red);
}
.phone {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 700;
}
.menuContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}
.menuItem {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
}
.menuItem:hover {
  color: var(--red);
}

:root {
  --red: rgb(227, 19, 53);
  --black: rgb(40, 40, 40);
  --white: #fff;
  --gray: #f7f7fb;
  --back-gray: #f7f7fb;
  --dark-gray: #eaeff5;
  --gray-bold: #444444;
  --dark-red: #980a21;
  --green: #159859;
}
* {
  color: var(--black);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "regular", arial, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 300ms ease-out;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; */
  /* font-display: optional; */
}
img,
svg {
  vertical-align: middle;
}
a {
  text-decoration-line: none;
}
h1 {
  font-size: 30px;
  font-weight: bold;
  font-family: "Mardoto-Regular";
  text-transform: uppercase;
}
input:focus {
  outline: none;
  border-color: var(--green);
}
.wrapper {
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  margin-bottom: 60px;
  position: relative;
}
.title {
  font-size: 30px;
  font-family: bold;
  text-transform: uppercase;
  margin: 20px 0;
}

/* swiper */
.swiper-scrollbar-drag {
  background-color: var(--red) !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  width: max-content !important;
  left: 50% !important;
  right: 50% !important;
  transform: translate(-50%, -50%);
  bottom: 26px !important;
  background: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 0 16px !important;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  padding: 0;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 7px !important;
  background-color: var(--black);
}

/* swiper end */
@media only screen and (max-width: 1024px) {
  .wrapper {
    padding: 0 30px;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 992px) {
  .productCard .swiper-slide {
    height: 350px !important;
  }
}
@media only screen and (max-width: 768px) {
  .swiper-pagination {
    display: none !important;
  }
  .title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

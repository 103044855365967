.contactsSection {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 40px 0 60px 0;
  background: var(--back-gray);
  border-radius: 5px;
  padding: 40px;
  gap: 10px;
  /* flex-wrap: wrap; */
  justify-content: space-around;
}
.line {
  height: 42px;
  border-right: 1px solid rgba(40, 40, 40, 0.1);
}
.contactItem {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  cursor: pointer;
}
.contactItem span {
  font-size: 20px;
  color: rgba(40, 40, 40, 0.7);
}
.contactItem:hover span {
  color: var(--red);
}
.roundSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #eaeff5;
  border-radius: 90px;
  padding: 15px;
  border: 1px solid rgba(40, 40, 40, 0.2);
}
.contactItem:nth-child(4) {
  flex-direction: column;
}
.contactItemTitle {
  font-size: 20px;
  font-weight: bold;
}
.socIconsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.socIconsContainer div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 6px;
  cursor: pointer;
}
.socIconsContainer div:hover {
  border-color: var(--red);
}
.socIconsContainer div:hover svg path {
  fill: var(--red);
}

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 50;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.openMenu {
  transform: translateX(0%);
}
.closeBtn {
  cursor: pointer;
}
.headerSection {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2828281a;
  padding: 15px 20px;
}
.headerLogo {
  width: 118px;
}
.menuCategories {
  position: relative;
  top: 0;
}
.categories {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #2828281a;
  cursor: pointer;
}
.headerCategorysItem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #2828281a;
  cursor: pointer;
}
.headerCategorysItem img {
  width: 20px;
  margin-right: 15px;
}
.brands {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
  padding: 10px;
}
.brandItem {
  height: 60px;
  min-width: max-content;
  text-transform: uppercase;
  padding: 15px 22px;
  background: #efeff6;
  border-radius: 5px;
  font-weight: 600;
  border: 1px solid transparent;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.brandItem img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.categoriesSection {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 60;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.categoriesSectionOpened {
  transform: translateX(0%) !important;
}
.categoriesSectionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 30px;
  border-bottom: 1px solid #2828281a;
  cursor: pointer;
}
.categoriesSectionItem:first-child {
  cursor: auto;
}
.backBtn {
  cursor: pointer;
}
.backBtn span {
  color: #cd0e0d;
  font-weight: bold;
  font-size: 14px;
  margin-left: 15px;
}
.categoriesSectionItem img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.categoriesSectionItem a {
  font-size: 14px;
  margin-left: 15px;
}

.container {
  position: absolute;
  top: 65px;
  width: 100%;
  left: 0;
  background: var(--white);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  padding: 12px 0;
  z-index: 13;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
}
.searchedProduct {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 36px;
  column-gap: 20px;
  cursor: pointer;
}
.searchedProdName {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.img {
  width: 56px;
  height: 56px;
  object-fit: contain;
}
.name {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  max-width: 160px;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
}

.main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
}
.sliderBlock {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: start;
  width: 100%;
}
.imgBlock {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
}
.imgBlock img {
  max-width: 827px;
  width: 100%;
  height: 400px;
  cursor: pointer;
  position: relative;
  object-fit: contain;
  border: 1px solid transparent;
}
.infoBlock {
  max-width: 478px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--back-gray);
  padding: 25px 28px 32px 28px;
}
.row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
}
.prodCategory {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: "bold";
  font-weight: bold;
}
.shareBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}
.compareHeart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76px;
  height: 38px;
  gap: 8px;
  padding: 10px;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
}
.compareHeart a svg {
  width: 18px;
  height: 18px;
}
.compareHeart a:hover svg path,
.compareHeart a:hover svg rect,
.shareBtn a:hover svg path {
  fill: var(--red) !important;
}
.compareHeart a span {
  height: 24px;
  border-right: 1px solid rgba(40, 40, 40, 0.2);
}
.shareBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #28282833;
}
.prodName {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "bold";
  font-weight: bold;
}

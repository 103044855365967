.container {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--back-gray);
}
.headLine {
  min-width: 1%;
  height: 5px;
  background-color: var(--red);
  transition: all linear 0.3s;
  margin-bottom: 10px;
}
.wrapper {
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}
.headerThreeBlocks {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.headerTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 20px;
  padding-bottom: 6px;
}
.leftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 35px;
}
.menuItem {
  color: rgba(40, 40, 40, 0.7);
  font-size: 16px;
  text-wrap: nowrap;
}
.menuItem:hover {
  color: var(--red);
}
.rightContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  column-gap: 20px;
  padding-bottom: 6px;
}

.socIconsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}
.socIcon path {
  fill: var(--black);
}
.socIcon:hover path {
  fill: var(--red);
}

.phones {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  position: relative;
}

.phones a {
  font-size: 20px;
  font-weight: 700;
}
.phones a:hover {
  text-decoration-line: underline;
  color: var(--red);
}
.phonesOpened {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  top: 30px;
  width: 100%;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0px 4.82129px 48.2129px rgb(0 0 0 / 5%);
}
.phonesOpened a {
  padding: 8px 12px;
  border-bottom: 1px solid #28282833;
}
.headerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  column-gap: 20px;
  padding-bottom: 6px;
}
.logo {
  max-width: 118px;
  min-width: 100px;
  height: 100vh;
  width: 100vw;
  max-height: 50px;
  cursor: pointer;
  object-fit: contain;
}
.catalogBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-bold);
  column-gap: 12px;
  min-width: max-content;
  padding: 16px 30px;
  border-radius: 5px;
  cursor: pointer;
}
.catalogBtn:hover {
  background-color: var(--black);
}
.catalogBtn span {
  color: var(--white);
  font-size: 14px;
}
.searchContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.searchInput {
  width: 100%;
  padding: 15px 65px 15px 20px;
  font-size: 14px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #28282833;
}
.searchBtn {
  display: flex;
  align-items: center;
  position: absolute;
  right: 9px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 9px 29px;
  color: var(--white);
  background-color: var(--red);
}
.searchBtn:hover {
  background-color: var(--dark-red);
}
.userBtn {
  display: flex;
  width: 46px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid #28282833;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  position: relative;
}
.userBtn:hover,
.userBtn:hover svg path {
  border-color: var(--red);
  fill: var(--red);
}
.compareAndFavContainer {
  display: flex;
  column-gap: 15px;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  width: 100px;
  height: 50px;
  padding: 13px 15px;
  cursor: pointer;
}
.compareAndFavContainer a {
  position: relative;
}
.compareAndFavContainer svg:hover path,
.compareAndFavContainer svg:hover rect {
  fill: var(--red);
}
.compareAndFavContainer .line {
  height: 24px;
  border-right: 1px solid rgba(40, 40, 40, 0.2);
}
.shopCartBtn {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  padding: 6px 14px;
  column-gap: 17px;
  align-items: center;
  cursor: pointer;
}
.shopCartBtn:hover,
.shopCartBtn:hover svg path {
  border-color: var(--red);
  fill: var(--red);
}
.shopCartBtnActive:hover,
.shopCartBtnActive:hover svg path {
  fill: var(--white);
}
.shopCartInfo {
  display: flex;
  flex-direction: column;
}
.shopCartBtn span:first-child {
  color: var(--black);
  opacity: 0.7;
  font-size: 12px;
  text-wrap: nowrap;
}
.shopCartBtn span:last-child {
  font-size: 14px;
  font-weight: bold;
  text-wrap: nowrap;
}
.shopCartBtnActive {
  background-color: var(--red);
}
.shopCartBtnActive svg path {
  fill: #fff;
}
.shopCartBtnActive span {
  color: #fff !important;
}
.count {
  position: absolute;
  background-color: var(--red);
  padding: 1px 4px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  top: -16px;
  right: -13px;
}
@media only screen and (max-width: 1440px) {
  .headerTop {
    column-gap: 20px;
  }
  .leftContent {
    column-gap: 28px;
  }
  .rightContent {
    column-gap: 20px;
  }
  .socIconsContainer {
    column-gap: 12px;
  }
  .phones {
    column-gap: 10px;
  }
  .phones a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1280px) {
  .phoneBtn {
    display: none;
  }
  .leftContent {
    column-gap: 22px;
  }
  .menuItem {
    font-size: 14px;
  }
  .shopCartBtn {
    height: 50px;
  }
  .shopCartInfo {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .headLine {
    margin-bottom: 5px;
  }
  .socIconsContainer {
    display: none;
  }
  .wrapper {
    padding: 0 30px;
  }
  .catalogBtn {
    width: 45px;
    min-width: 45px;
    height: 45px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .catalogBtn span {
    display: none;
  }
  .searchInput {
    padding: 13px 65px 13px 20px;
  }
  .searchBtn {
    height: calc(100% - 14px);
    padding: 6px 24px;
  }
}
@media only screen and (max-width: 992px) {
  .headLine {
    margin-bottom: 10px;
  }
  .rightContent {
    column-gap: 30px;
  }
  .headerBottom {
    flex-direction: column;
    row-gap: 10px;
  }
  .catalogBtn {
    width: 100%;
    height: 45px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .catalogBtn span {
    display: flex;
  }
}
@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 430px) {
  .logo {
    max-width: 100px;
    min-width: 100px;
  }
  .rightContent {
    column-gap: 22px;
  }
  .searchInput {
    padding: 16px;
  }
  .catalogBtn {
    height: 40px;
  }
}

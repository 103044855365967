.container {
  display: flex;
  flex-direction: column;
  max-width: 218px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 15px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.brandImg {
  object-fit: contain;
  max-height: 30px;
  max-width: 60px;
  width: 100%;
}
.image {
  height: 176px;
  padding-top: 25px;
  object-fit: contain;
}
.descContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.descContainer a:hover .productName {
  text-decoration: underline;
}
.categoryName {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: "Mardoto-Bold";
  width: 100%;
  font-size: 10px;
  margin-bottom: 5px;
  word-break: break-word;
}
.productName {
  width: 100%;
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
  height: 32px;
}
.price {
  font-size: 18px;
  font-weight: bold;
}
.promoPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  column-gap: 8px;
  width: 100%;
}
.newPrice {
  font-size: 18px;
  font-weight: bold;
  color: var(--red);
}
.oldPriceContainer {
  position: relative;
}
.oldPrice {
  font-size: 12px;
  color: #898383;
}
.oldPriceContainer .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 6px;
}
.priceMounth {
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
  margin-top: 10px;
}
.btnsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  justify-content: start;
  align-items: center;
}
.cardBtn {
  padding: 15px 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--red);
  border-radius: 5px;
  column-gap: 10px;
}
.cardBtnSvg {
  width: 15px;
  height: 15px;
}
.cardBtnSvg path {
  fill: #fff;
}
.cardBtn span {
  color: #fff;
  font-size: 12px;
}
@media only screen and (max-width: 992px) {
  .image {
    height: 100%;
    width: 100%;
  }
  .productCard .slideItem {
    height: 350px !important;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    max-width: none;
    padding: 6px 12px 12px 12px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}
.slideItem {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;
  min-width: max-content;
  text-transform: uppercase;
  padding: 15px 22px;
  background: #efeff6;
  border-radius: 5px;
  font-weight: 600;
  border: 1px solid transparent;
  cursor: pointer;
}
.slideItem:hover {
  color: var(--red);
  border-color: var(--red);
}
.headerCategorysLogo {
  max-width: 120px;
  max-height: 20px;
  height: 100vh;
  width: 100vw;
  padding: 0 22px;
}
.headerCategorysLoad {
  column-gap: 20px;
}
.loadContent {
  width: 311px;
  height: 62px;
}
@media only screen and (max-width: 1024px) {
  .slideItem {
    height: 40px;
    font-size: 12px;
    padding: 6px 16px;
    column-gap: 14px;
  }
  .loadContent {
    width: 245.7px;
    height: 40px;
  }
}
@media only screen and (max-width: 992px) {
  .slideItem .headerCategorysIcon {
    max-width: 24px;
  }
  .loadContent {
    width: 238.7px;
  }
}
@media only screen and (max-width: 430px) {
  .slideItem {
    height: 38px;
    font-size: 12px;
    padding: 10px 12px;
    column-gap: 10px;
  }
  .loadContent {
    width: 226.7px;
    height: 38px;
  }
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  width: 100%;
  border: 1px solid var(--dark-gray);
  font-size: 14px;
  padding: 10px 10px;
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.openedContainer {
  position: absolute;
  background-color: #fff;
  z-index: 99;
  top: 55px;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-family: bold;
  width: 160px;
  box-shadow: 0px 0px 30px -10px var(--dark-gray);
}
.openedContainer p {
  font-size: 14px;
  color: var(--black);
  line-height: 30px;
  display: flex;
  min-height: 30px;
  cursor: pointer;
}

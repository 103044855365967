.background {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--red);
  z-index: 9999;
  justify-content: center;
  align-items: center;
}
.dotsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 15px;
  justify-content: center;
}
.dot {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  animation: dot-animation 1s infinite linear alternate;
}
.dot1 {
  animation-delay: 0.5s;
}
.dot2 {
  animation-delay: 1s;
}
.dot3 {
  animation-delay: 1.5s;
}
.dot4 {
  animation-delay: 2s;
}

@keyframes dot-animation {
  0% {
    background-color: #fff;
  }
  50%,
  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.container {
  position: absolute;
  top: 52px;
  left: -30px;
  z-index: 10;
  max-width: 280px;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--white);
  border: 1px solid rgba(40, 40, 40, 0.1);
  border-radius: 5px;
}
.btn {
  width: 100%;
  padding: 17px 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  border: none;
  background-color: var(--white);
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
}
.btn span {
  font-size: 16px;
}
.btn:hover {
  background-color: var(--red);
}
.btn:hover span {
  color: var(--white);
}
.btn:hover svg path {
  fill: var(--white);
}

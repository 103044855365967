.container {
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  white-space: nowrap;
}
.slideItem {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 19px;
  min-width: 290px;
  width: 290px;
  border-radius: 5px;
  justify-content: start;
  column-gap: 15px;
  border: 2px solid#EEEEEE;
}
.loadContent {
  width: 290px;
  height: 102px;
}
.slideItem:hover {
  color: var(--red);
  border-color: var(--red);
}
.slideItemImg {
  width: 60px;
  height: 60px;
  background: #efeff6;
  border-radius: 30px;
  padding: 11px;
}
.slideItemTitle {
  text-wrap: wrap;
  font-size: 14px;
  max-width: 148px;
}
.headerCategorysLogo {
  max-width: 120px;
  max-height: 20px;
  height: 100%;
  padding: 0 22px;
}
.headerCategorysLoad {
  column-gap: 20px;
}
@media only screen and (max-width: 1280px) {
  .slideItem {
    width: 240px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    margin: 20px 0 36px 0;
  }
}
@media only screen and (max-width: 430px) {
  .container {
    margin: 20px 0 30px 0;
  }
  .slideItem {
    min-width: 250px;
    padding: 10px;
  }
  .loadContent {
    width: 250px;
    height: 84px;
  }
}

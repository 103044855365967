.container {
  max-width: 336px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  border: 1px solid #eeeeee;
  background-color: var(--white);
  padding: 14px 30px 16px 16px;
  flex-direction: row;
  column-gap: 10px;
}
.leftBlock {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  max-width: 120px;
  width: 100%;
}
.logo {
  max-width: 60px;
  max-height: 20px;
}
.logo img {
  width: 100%;
  height: 100%;
  max-width: 60px;
  max-height: 20px;
  object-fit: contain;
}
.imgs {
  height: 120px;
  padding-top: 25px;
  display: block;
  position: relative;
}
.imgs img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.rightBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: space-between;
}
.categoryName {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: "Mardoto-Bold";
  width: 100%;
  font-size: 10px;
  margin-bottom: 5px;
  word-break: break-word;
}
.productName {
  width: 100%;
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
  height: 32px;
}
.price {
  font-size: 18px;
  font-weight: bold;
}
.promoPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  column-gap: 8px;
  width: 100%;
}
.newPrice {
  font-size: 18px;
  font-weight: bold;
  color: var(--red);
}
.oldPriceContainer {
  position: relative;
}
.oldPrice {
  font-size: 12px;
  color: #898383;
}
.oldPriceContainer .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 6px;
}
.priceMounth {
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
  margin-top: 10px;
}
.btnsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  justify-content: start;
  align-items: center;
}
.cardBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 14px;
  border-radius: 5px;
  column-gap: 10px;
  cursor: pointer;
  background-color: var(--red);
  width: 100%;
}
.cardBtnSvg {
  width: 10px;
  height: 11px;
}
.cardBtnSvg path {
  fill: #fff;
}
.cardBtn span {
  color: #fff;
  font-size: 10px;
}
.rightBlock a:hover .productName {
  text-decoration: underline;
}

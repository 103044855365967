.container {
  display: flex;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 45;
  align-items: center;
  column-gap: 10px;
  justify-content: space-around;
  padding: 25px 10px 30px 10px;
  background-color: var(--white);
}
.container div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container div span {
  padding: 1px 5px;
  border-radius: 90px;
  color: var(--white);
  font-size: 12px;
  position: absolute;
  top: -15px;
  right: -19px;
  background-color: var(--red);
}
.container svg {
  cursor: pointer;
  width: 29px;
  height: 28px;
}
.container svg:hover path,
.container svg:hover rect {
  fill: var(--red);
}

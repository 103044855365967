.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 50;
  transform: translateX(-100%);
  display: flex;
  flex-direction: row;
  padding: 30px;
}
.openMenu {
  transform: translateX(0%);
}
.catalogList {
  width: 20%;
  height: 100%;

  padding-right: 15px;
}
.catalogList .menuItem {
  display: flex;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}
.menuItem img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.menuItem a {
  font-size: 14px;
  margin-left: 15px;
}
.catalogList .menuItemActive,
.catalogList .menuItem:hover {
  border-radius: 5px;
  background-color: #eaeff5;
}
.catalogList .menuItemActive a,
.catalogList .menuItem:hover a {
  color: var(--red);
}
.catalogContent {
  width: 68%;
  height: 100%;
  padding: 20px;
  border-left: 1px solid #0000001a;
  margin: 0 15px;
  overflow-y: scroll;
}
.closeBtn {
  cursor: pointer;
}
.banerImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.content {
  margin-top: 30px;
  /* overflow-y: scroll; */
  height: 100%;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  column-gap: 15px;
}
.content img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.titleContainer .title {
  color: rgb(153, 3, 6);
  font-size: 20px;
}
.titleContainer .count {
  padding: 4px 16px;
  color: var(--white);
  font-weight: bold;
  background-color: var(--red);
  border-radius: 90px;
  font-size: 18px;
}
.subCategories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  justify-content: space-between;
}
.subCategory {
  /* grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 1; */
}
.subCategoryTitle {
  margin-top: 15px;
  display: flex;
  column-gap: 15px;
  cursor: pointer;
}
.subCategoryTitle h6 {
  font-weight: bold;
  font-size: 16px;
}
.subCategoryTitle:hover h6 {
  color: var(--red);
}
.subCategorySubs,
.subCategorySubs div {
  margin-top: 15px;
}
@media (min-width: 907px) and (max-width: 1400px) {
  .catalogList {
    width: 31%;
  }
}

.container {
}
.title {
  font-size: 30px;
  font-family: bold;
  text-transform: uppercase;
  margin: 20px 0;
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
}
.slidePrevArrow {
  left: 15px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 15px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}
.loadContent {
  width: 196px;
  height: auto;
  aspect-ratio: 5.4/ 10;
}
@media only screen and (max-width: 768px) {
  .title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 600px) {
  .loadContent {
    width: 190px;
  }
}

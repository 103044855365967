.titleBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.moreBtn {
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
}
.titleBlock .moreBtn:hover {
  color: var(--red);
  text-decoration: none;
}
.brandItem {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 30px 50px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}
.brandItem:hover {
  border-color: var(--red);
}
.loadContent {
  width: 100%;
  height: auto;
  min-height: 20px;
  aspect-ratio: 10 / 4.2;
}
.brandItemImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 5px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 5px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}
@media only screen and (max-width: 992px) {
  .brandItem {
    padding: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .moreBtn {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .brandItem {
    row-gap: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .brandItem {
    height: 60px;
    padding: 10px;
  }
}

.main {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
  border-radius: 5px;
  column-gap: 20px;
  padding: 44px;
  background-size: cover;
  justify-content: space-around;
  background-repeat: no-repeat;
  background-image: url(./assets/TreeInOneBg.png);
}
.leftBlock {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  max-width: 530px;
}
.rightBlock {
  display: flex;
  align-items: end; /* center */
  position: relative;
}
.leftBlock .title,
.leftBlock .title span,
.leftBlock .title b {
  font-size: 48px;
  text-transform: uppercase;
  font-family: "bold" !important;
}
.priceBlock {
  display: flex;
  column-gap: 18px;
  align-items: center;
  padding-left: 50px;
}
.bigPrice {
  font-size: 40px;
  font-family: "bold";
}
.btnsBlock {
  display: flex;
  flex-direction: row;
  row-gap: 28px;
  max-width: 530px;
  column-gap: 36px;
}
.btn {
  display: flex;
  padding: 18px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--red);
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btn span {
  font-size: 12px;
  color: var(--white);
}
.btn svg path {
  fill: var(--white);
}
.btn:hover {
  background-color: var(--dark-red);
}
@media only screen and (max-width: 1440px) {
  .leftBlock .title,
  .leftBlock .title span,
  .leftBlock .title b {
    font-size: 42px;
  }
  .bigPrice {
    font-size: 36px;
  }
  .btnsBlock {
    column-gap: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .main {
    margin-bottom: 56px;
  }
  .leftBlock {
    width: 35%;
  }
  .leftBlock .title,
  .leftBlock .title span,
  .leftBlock .title b {
    font-size: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .main {
    flex-direction: column;
    row-gap: 50px;
  }
  .leftBlock {
    max-width: none;
    width: 100%;
    align-items: center;
  }
  .leftBlock .title {
    text-align: center;
  }
}
@media only screen and (max-width: 430px) {
  .main {
    padding: 18px;
  }
  .leftBlock {
    gap: 20px;
  }
  .btnsBlock {
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
  .btn {
    width: 100%;
    padding: 10px;
  }
  .btn span {
    font-size: 10px;
  }
}
